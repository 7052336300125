.image404 {
	height: 80vh;
	text-align: center;
	margin: 0 auto;
}

.image5xx {
	height: 100vh;
	text-align: center;
	margin: 0 auto;
}
