.react-tagsinput {
    background-color: #fff;
    border:1px solid rgba(34,36,38,.15);
    border-radius:.28571429rem;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
}

.react-tagsinput--focused {
    border-color:#85b7d9;
}

.react-tagsinput-tag {
    background-color: #e0ebeb;
    border-radius: 2px;
    border: 1px solid #85b7d9;
    color: #3d5c5c;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    content: " ×";
}

.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: rgba(0,0,0,.87);
    font-family:Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 100px;
}
